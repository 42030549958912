import Image, { StaticImageData } from "next/image"
import { Box } from "@mui/material"

export type BackgroundImage = {
  image: StaticImageData
  alt?: string
  quality?: number
  priority?: boolean
}

const BackgroundImage = (props: BackgroundImage) => {
  const { image, alt = "", quality = 75, priority = false } = props

  return (
    <Box
      component="div"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        overflow: "hidden",
        img: {
          objectFit: "cover",
          objectPosition: "50% 50%",
          width: "100%",
          height: "100%",
        },
      }}
    >
      {image && <Image alt={alt} src={image} quality={quality} placeholder="blur" priority={priority} />}
    </Box>
  )
}

export default BackgroundImage
