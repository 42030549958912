import { useEffect, useState } from "react"
import NextLink from "next/link"
import { StaticImageData } from "next/image"
import { useRouter } from "next/router"
import { AuthTemplate, Input, Button } from "@parallel-domain/pd-theme"
import { signIn, SignInResponse } from "next-auth/react"
import { Box, Stack, Link, Typography, CircularProgress } from "@mui/material"
import axios, { AxiosResponse } from "axios"
import { toast } from "react-toastify"
import parse from "html-react-parser"
import { Controller, useForm } from "react-hook-form"

// import app components
import useCustomSession from "features/authentication/hooks/useSession"
import BackgroundImage from "components/backgroundImage"
import image from "assets/images/night-rain-crosswalk.png"
import type { UserApiError } from "../types"

const Login = () => {
  const [form, setForm] = useState("email")
  const [loading, setLoading] = useState<"email" | "sso" | "code" | "redirect" | "pre-check" | "">("")
  const [callback, setCallback] = useState("")

  const { handleSubmit, control } = useForm()

  const router = useRouter()

  const { data: session, status } = useCustomSession()

  /**
   * Update callback url if parameter is provided
   */
  useEffect(() => {
    if (router?.query?.callback) {
      setCallback(router.query.callback as string)
    }
  }, [router?.query?.callback])

  /**
   * Redirect user in case authentication status changes
   */
  useEffect(() => {
    if (status === "authenticated") {
      if (callback) {
        router.push(callback)
      } else if (
        session?.user?.activeOrganization?.capabilities &&
        session.user.activeOrganization.capabilities.includes("step")
      ) {
        router.push("/data-lab/dashboard")
      } else {
        router.push("/datasets")
      }
    }
  }, [session, status, callback])

  // Sign in user on redirect
  useEffect(() => {
    const signInWithSSO = async () => {
      setLoading("code")

      const { code, state: callback } = router.query

      if (callback) {
        setCallback(callback as string)
      }

      // Remove code GET parameter from URL
      router.replace(router.pathname, undefined, { shallow: true })

      const response: SignInResponse | undefined = await signIn("sso", { code, redirect: false })

      if (response?.ok) {
        setLoading("redirect")
      } else {
        toast.error(<>{parse(response?.error || "Something went wrong")}</>)
        setLoading("")
      }
    }

    if (router?.query?.code) {
      signInWithSSO()
    }
  }, [router])

  const handleLoginWithEmailPassword = async (formData: { email: string; password: string }) => {
    setLoading("email")

    const response: SignInResponse | undefined = await signIn("credentials", { ...formData, redirect: false })

    if (!response?.ok) {
      toast.error(<>{parse(response?.error || "Something went wrong")}</>)
      setLoading("")
    }
  }

  const handlePreLogin = async (formData: { email: string }) => {
    try {
      setLoading("pre-check")

      const response: AxiosResponse<{ type: string; url?: string }> = await axios.post(
        `${process.env.NEXT_PUBLIC_PD_USER_API}/api/v1/auth/pre-login`,
        formData
      )

      if (response.data.type === "password") {
        setForm("password")
        setLoading("")
      } else if (response.data.type === "sso") {
        let url = response.data.url || ""

        if (router?.query?.callback) {
          const delimiter = url.includes("?") ? "&" : "?"
          url = `${url}${delimiter}state=${router.query.callback}`
        }

        // Redirect to sso provider URL
        window.location.replace(url)
      }
    } catch (e) {
      const error = e as UserApiError
      toast.error(<>{parse(error.response?.data?.detail || "Something went wrong")}</>)
      setLoading("")
    }
  }

  return (
    <AuthTemplate
      quote="Their platform provided us with a diverse dataset with edge cases and accurate annotations that would not have been possible with our real world data operations."
      authorOrRole="Adrien Gaidon"
      description="Senior Machine Learning Manager, Toyota Research Institute"
      backgroundImage={<BackgroundImage image={image as StaticImageData} quality={100} priority />}
      message="Nice to see  you again!"
      highlightLogin={true}
      linkComponent={NextLink}
      loginLink={"/auth/login"}
      registerLink={"/auth/register"}
    >
      {loading === "code" || loading === "redirect" ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
          <CircularProgress />
        </Box>
      ) : (
        <form
          method="post"
          noValidate
          onSubmit={(e) => {
            e.preventDefault()

            if (loading) return

            if (form === "email") {
              handleSubmit(handlePreLogin)(e)
            } else {
              handleSubmit(handleLoginWithEmailPassword)(e)
            }
          }}
        >
          <Stack spacing={4}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Please enter your email address",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Please enter a valid email",
                },
              }}
              render={({ field: { onChange }, fieldState: { error } }) => {
                return (
                  <Input
                    label="Email"
                    inputProps={{ "data-testid": "email" }}
                    placeholder="johndoe@workemail.com"
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    type="email"
                    fullWidth
                    disabled={form !== "email"}
                  />
                )
              }}
            />

            <Controller
              name="password"
              control={control}
              rules={{
                ...(form === "password" && {
                  required: "Please enter your password",
                }),
              }}
              render={({ field: { onChange }, fieldState: { error } }) => {
                if (form !== "password") {
                  return null
                }

                return (
                  <Stack spacing={1}>
                    <Input
                      label="Password"
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      type="password"
                      fullWidth
                    />

                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Typography variant="body2">
                        <Link
                          href="/auth/reset-password"
                          component={NextLink}
                          underline="hover"
                          sx={{ color: "inherit" }}
                        >
                          Forgot Your Password?
                        </Link>
                      </Typography>
                    </Box>
                  </Stack>
                )
              }}
            />

            <Stack spacing={3}>
              <Button
                loading={loading === "pre-check" || loading === "email"}
                variant="outlined"
                type="submit"
                fullWidth
              >
                {form === "email" ? "Next" : "Login"}
              </Button>
            </Stack>
          </Stack>
        </form>
      )}
    </AuthTemplate>
  )
}

export default Login
